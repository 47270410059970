
function changeText($this, text) {
  setTimeout(function() {
    $this.children('[data-change-text]').text(text);
  }, 300)
}

function initFaq() {
  $('[data-faqItem-button]:not(.initialized)').each(function() {
    $(this).addClass('initialized');

    $(this).click(function(e) {
      $(this).parent('dt')
        .toggleClass('expanded')
        .next('[data-faqItem-content]')
        .slideToggle();
    });
  });

  $('[data-faq-additional]:not(.initialized)').each(function() {
    $(this).addClass('initialized').hide();

  });

  $('[data-faq-more]:not(.initialized)').each(function() {
    $(this).addClass('initialized');

    $(this).click(function(e) {
      $(this).closest('[data-faq-lists]').find('[data-faq-additional]').slideDown();
      $(this).closest('[data-faq-lists]').find('[data-faq-less]').show();
      $(this).hide();
    });
  });

  $('[data-faq-less]:not(.initialized)').each(function() {
    $(this).addClass('initialized').hide();

    $(this).click(function(e) {
      $(this).closest('[data-faq-lists]').find('[data-faq-additional]').slideUp();
      $(this).closest('[data-faq-lists]').find('[data-faq-more]').show();
      $(this).hide();
    });
  });
}

readyCallbacks.add(() => {
  initFaq();
});